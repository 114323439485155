@import '../../scss/colors';
@import '../../scss/reponsive';

.modal {
    &__title {
        width: 100%;
        text-align: center;
        margin-bottom: 1.5rem;
    }

    &__button {
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
    }
}