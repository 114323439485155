@import "../../scss/colors";

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .logo {
        width: 16rem;
        height: auto;
        margin: .5rem;
    }

    .user {
        display: flex;
        align-items: center;
        color: $primary-grey;
        margin-right: 2rem;

        .name {
            display: flex;

            &:before {
                content: '';
                display: block;
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                margin-right: 0.5rem;
                background: $light-grey;
            }
        }
    }
}