.print--hidden {
    @media print {
        display: none !important;
    }
}

.print--table-row {
    @media print {
        display: table-row !important;
    }
}

.print--w100 {
    @media print {
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
    }
}