@import 'scss/_typography.scss';
@import 'scss/print';
@import 'scss/ie-fix.scss';

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: Allianz, Arial, sans-serif;
  min-height: 100%;
}

.main {
  display: flex;
  flex: 1 0 auto;
  width: 100%;
}

.sidebar {
  flex: 0 0 4.2rem;
}

.app-content {
  display: flex;
  flex: 1 0 auto;
  padding: 0 1rem;
  
    > div {
      width: 100%;
    }
}

.pointer {
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.mw-600 {
  max-width: 600px;
}

* {
  -webkit-overflow-scrolling: touch;
}

.filters > * {
  min-width: 10rem;
  max-width: 16rem;
  margin: 0 1rem 1rem;
}

.new-user-col {
  width: 50%;
}

.new-user-grid {
  width: 70%;
}