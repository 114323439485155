@import '../../scss/colors';

.table {
    max-width: calc(100vw - 70px);
    
    td {
        max-width: 15rem;
    }
}
.loading {
    opacity: .3;
}

.page-item .page-link {
    color: $eh-blue;
}

.page-item.active .page-link {
    border-color: $eh-blue;
    background-color: $eh-blue;
}

.filters {
    align-items: center;
}