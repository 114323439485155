.container-logged-out button {
    margin-left: auto;
    margin-right: auto;
    width: 16rem;
}

.button-connection {
    color: #ffffff;
}

.button-connection:hover {
    color: #ffffff;
    text-decoration: none;
}