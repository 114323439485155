.flx {
    display: flex;

    &__row {
        flex-direction: row;

        &--spaced > * {
            margin-right: 1rem;
        }
    }

    &__col {
        flex-direction: column;
    }

    &.algn-start {
        align-items: flex-start;
    }

    &.algn-end {
        align-items: flex-end;
    }

    &.algn-center {
        align-items: center;
    }

    &.just-start {
        justify-content: flex-start;
    }

    &.just-end {
        justify-content: flex-end;
    }

    &.just-center {
        justify-content: center;
    }

    &.just-space-between {
        justify-content: space-between;
    }
}
