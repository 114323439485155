@import '../../scss/colors';

.sidebar {
    &__menu {
        position: sticky;
        top: 0;
        display: flex;
        flex-direction: column;
    
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 4.2rem;
          height: 4.2rem;
          background: $white;
          color: $eh-blue;

          &:hover,
          &.active {
              background: $eh-blue;
              color: $white;
          }
        }
    }
}