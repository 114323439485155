@import './colors';

.display2 {
    font-weight: ultra-black;
    font-size: 40px;
    line-height: 42px;
}

.title {
    font-weight: light;
    font-size: 26px;
    line-height: 34px;
}

.subheading {
    font-weight: light;
    font-size: 22px;
    line-height: 30px;
}

.body2-light {
    font-weight: light;
    font-size: 18px;
    line-height: 26px;
}

.body2-regular {
    font-weight: regular;
    font-size: 18px;
    line-height: 26px;
}

.body1-light {
    font-weight: light;
    font-size: 16px;
    line-height: 22px;
}

.caption {
    font-weight: regular;
    font-size: 14px;
    line-height: 19px;
}

.footnote {
    font-weight: light;
    font-size: 13px;
    line-height: 19px;
}

.label {
    font-weight: light;
    font-size: 12px;
    line-height: 15px;
}

.bold {
    font-weight: bold;
}

.text--error {
    color: $red;
}

a,
.text-blue {
    color: $eh-blue;
}