@import '../../scss/colors';
@import '../../scss/reponsive';

.input-field__group {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 14rem;
}

.input-field__label {
    text-align: left;
}

.input-field {
    border-radius: 3px;
    box-shadow: none;
    font-family: "AllianzNeoLightBold", "Arial", "Verdana", sans-serif;
    border: solid $medium-grey 1px;
    padding: .5rem;
    width: 100%;

    &--date {
        max-width: 14rem;
    }

    &--file {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 0px;
        cursor: pointer;
        background-color: $white;

        @media ($tablet) {
            width: 42rem;
            max-width: 100%;
        }

        .button {
            flex: 1 0 50%;
            display: flex; 
            justify-content: space-around;
            justify-content: space-evenly;
            align-items: center;
            border-radius: 0 2px 2px 0;
            font-weight: normal;
            text-transform: inherit;
            transition: opacity .3s ease;
            
            @media ($tablet) {
                flex: 1 0 40%;
            }
        }

        .file-name {
            flex: 1 0 50%;
            max-height: 1.6rem;
            color: $primary-grey;
            overflow: hidden;
            
            @media ($tablet) {
                flex: 1 0 60%;
            }
        }

        input[type="file"] {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            z-index: 10;
            cursor: pointer;
        }
    }

    &__progress-bar {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background: $ultra-light-green;
        opacity: 90%;
        z-index: 11;
        transition: width .3s linear;
    }

    &:focus {
        box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.3) !important;
    }

    &:disabled {
        cursor: not-allowed;
        background: $light-grey;
        color: $primary-grey;
    }

    &::placeholder {
        font-weight: normal;
    }

    &.error {
        color: $error-form-field;
    }
}
.input-field__currency {
    margin-left: .5rem;
}

.input--textarea {
    flex: 1 1 100%;
    min-height: 8rem;
    max-height: 12rem;
}