@import '../../scss/colors';

.dropdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;

    &__label {
        text-align: left;
    }

    &__select {
        height: 42px;
        background-color: $white;
        border: 1px solid $medium-grey;
        border-radius: 3px;
        font-weight: bold;
    }
}