// primary palette
$eh-blue: #0065AE;
$eh-night: #002F3C;
$eh-beige: #F5F3ED;
$white: #fff;

// Greys palette
$deep-grey: #414141;
$primary-grey: #7A7A7A;
$medium-grey: #C6C6C6;
$light-grey: #D9D9D9;
$ultra-light-grey: #ECECEC;

// Green palette
$green: #1A8067;
$light-green: #57A574;
$ultra-light-green: #93CA7F;

// Yellow palette
$yellow: #F2D33C;
$light-orange: #F2AF3C;
$orange: #EA8834;
$dark-orange: #E16132;

// Red palette
$red: #E62336;
$medium-red: #A31F34;
$eh-red: #771626;
$dark-red: #410B13;

// Grades
$grade-1: $green;
$grade-2: $light-green;
$grade-3: $ultra-light-green;
$grade-4: $yellow;
$grade-5: $light-orange;
$grade-6: $orange;
$grade-7: $red;
$grade-8: $medium-red;
$grade-9: $eh-red;
$grade-10: $dark-red;

// Errors
$error-form-field: $red;
$error-technical: $dark-orange;