@import '../../scss/colors';

.table {
    max-width: 100%;
    border-collapse: collapse;

    th {
        font-size: 0.8rem;
    }

    td {
        font-size: 0.75rem;
    }
}